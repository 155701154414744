'use strict';

$(() => {

    // クッキーを確認して表示するか確認する
    $('div.information-bar-inner').each(function(index, element) {
        const cookieName = $(element).find('div.information-bar-close').data('id');
        const isCloseable = $(element).find('div.information-bar-close').data('closeable');
        let isExistCookie = document.cookie.split(';').find(row => row.trim().startsWith(cookieName));
        if (isExistCookie && isCloseable) {
            const cookieValue = isExistCookie.split('=')[1];
            if (cookieValue !== 'true') {
                $(this).show();
            }
        } else {
            $(this).show();
        }
    });

    // 強調CSSのクラス付与
    $('div.information-bar-text').find('strong').addClass('bold');

    // 斜線CSS付与
    $('div.information-bar-text').find('em').css('font-style', 'italic');

    // closeボタン押下
    $('div.information-bar-close').on('click', function(e) {
        e.preventDefault();
        const bar = $(this).parent();
        // isCloseableがtrueの時にはcookieに値を保存
        var url = $('div.information-bar-data-container').data('url');
        var tokenName = $('div.information-bar-data-container').data('tokenname');
        var token = $('div.information-bar-data-container').data('token');
        var data = {};
        data[tokenName] = token;
        data['informationId'] = $(this).data('id');
        if ($(this).data('closeable')) {
            $.ajax({
                url: url,
                type: 'post',
                data: data,
                dataType: 'json',
                success: function (response) {
                    if (response.success) {
                        bar.remove();
                        $('.jsHeader').css('top', 0);
                    }
                },
                error: function (err) {
                    // Expected error response is for CSRF failure, which will include a redirect to CSRF-Fail
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                }
            });
        } else {
            bar.remove();
            $('.jsHeader').css('top', 0);
        }
    });

});
