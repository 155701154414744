'use strict';

const emailValidation = require('./components/emailValidation');

function submitSendGrid() {
    const form = $('form.sendgrid');
    form.spinner().start();

    // メッセージの削除
    form.find('div.invalid-feedback').text('');
    form.find('div.success-feedback').text('');

    // emailの確認
    const emailInput = form.find('input.input__text');
    const email = emailInput.val();
    if (!email) {
        form.find('div.invalid-feedback').text(emailInput.data('missing-error'));
        form.spinner().stop();
        return false;
    }
    if (!emailValidation(email)) {
        form.find('div.invalid-feedback').text(emailInput.data('pattern-mismatch'));
        form.spinner().stop();
        return false;
    }

    const url = form.attr('action');
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            if (data.success) {
                // 成功メッセージの表示
                form.find('div.success-feedback').text('We have added your address to our newsletter subscribers.');
            } else {
                // 失敗メッセージの表示
                form.find('div.invalid-feedback').text(
                    'Some error occurred when adding for the newsletter subscribers.'
                     + 'Please take some time to retry, or contact our customer support with the error message below.'
                     + data.errorText
                );
            }
            form.spinner().stop();
        },
        error: function (data) {
            if (data.responseJSON.redirectUrl) {
                window.location.href = data.responseJSON.redirectUrl;
            } else {
                const errorMessage = data.responseJSON.error.errorText;
                form.find('div.invalid-feedback').text(errorMessage);
                form.spinner().stop();
            }
        }
    });
}
    

$(() => {

    $('body').on('click', 'button.button-footer-email-submit', function(e) {
        e.preventDefault();
        submitSendGrid();
    });

    $('body').on('keypress', 'form.sendgrid', function(e) {
        if(e.which == 13) {
            e.preventDefault();
            submitSendGrid();
        }
    });
});
