"use strict";

var debounce = require("lodash/debounce");
var endpoint = $(".suggestions-wrapper").data("url");
var minChars = $(".suggestions-wrapper").data("min-chars") || 10;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).siblings(".suggestions-wrapper");
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest(".search-mobile").length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals(menuType) {
    $("body").removeClass("modal-open");
    $("header").siblings().attr("aria-hidden", "false");
    $(".suggestions").removeClass("modal");
    if (menuType === "pc") {
        $("#sp-search-suggestions-result").empty();
    } else {
        $(".cmn-header__navigation-pc-search").empty();
    }
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $("body").addClass("modal-open");
        $("header").siblings().attr("aria-hidden", "true");
        getSuggestionsWrapper(scope).find(".suggestions").addClass("modal");
    }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $("input.search-field").val("");
    clearModals();
    $(".search-mobile .suggestions").unbind("scroll");
    $(".suggestions-wrapper").empty();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = ".search-mobile button.";
    var iconSearch = "fa-search";
    var iconSearchClose = "fa-close";

    if (action === "close") {
        $(mobileSearchIcon + iconSearch)
            .removeClass(iconSearch)
            .addClass(iconSearchClose)
            .attr("type", "button");
    } else {
        $(mobileSearchIcon + iconSearchClose)
            .removeClass(iconSearchClose)
            .addClass(iconSearch)
            .attr("type", "submit");
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (
        $(scope).scrollTop() + $(scope).innerHeight() >=
        $(scope)[0].scrollHeight
    ) {
        $(".more-below").fadeOut();
    } else {
        $(".more-below").fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find(".suggestions");
        $suggestions.css("top", top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 * @param {string} menuType - PC or SP
 */
function processResponse(response, menuType, searchInput) {
    var $suggestionsWrapper = $(".cmn-header__navigation-pc-search").empty();
    if (menuType === "sp") {
        var $suggestionsWrapper = $("#sp-search-suggestions-result").empty();
    }
    storeSearchInput(searchInput);
    $.spinner().stop();
    if (typeof response !== "object") {
        $suggestionsWrapper.append(response).show();
        $('.cmn-header__navigation-pc__inner').animate({
            height: 410,
        }, 300);
        $(this).siblings(".reset-button").addClass("d-sm-block");
        positionSuggestions(this);

        if (isMobileSearch(this)) {
            toggleSuggestionsIcon("close");
            applyModals(this);
        }

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $(".suggestions .item");
        if ($(suggestionsList).length) {
            $("input.search-field").attr(
                "aria-describedby",
                "search-result-count"
            );
        } else {
            $("input.search-field").removeAttr("aria-describedby");
        }
    } else {
        $suggestionsWrapper.empty();
    }
    appendSearchHistory();
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    var menuType = $(scope).data("type");
    let requestURL =
        endpoint + encodeURIComponent($(scope).val()) + "&type=" + menuType;
    if ($(scope).val().length >= minChars) {
        $.spinner().start();
        $.ajax({
            context: scope,
            url: requestURL,
            method: "GET",
            success: function (response) {
                processResponse(response, menuType, $(scope).val());
            },
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        toggleSuggestionsIcon("search");
        $(scope).siblings(".reset-button").removeClass("d-sm-block");
        clearModals(menuType);
        getSuggestionsWrapper(scope).empty();
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $(".suggestions .item");
    if (suggestionsList.filter(".selected").length === 0) {
        suggestionsList.first().addClass("selected");
        $("input.search-field").each(function () {
            $(this).attr(
                "aria-activedescendant",
                suggestionsList.first()[0].id
            );
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass("selected")) {
                $(this).removeClass("selected");
                $(this).removeAttr("aria-selected");
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass("selected");
                    suggestionsList.eq(idx).attr("aria-selected", true);
                    $(this).removeProp("aria-selected");
                    $("input.search-field").each(function () {
                        $(this).attr(
                            "aria-activedescendant",
                            suggestionsList.eq(idx)[0].id
                        );
                    });
                } else {
                    suggestionsList.first().addClass("selected");
                    suggestionsList.first().attr("aria-selected", true);
                    $("input.search-field").each(function () {
                        $(this).attr(
                            "aria-activedescendant",
                            suggestionsList.first()[0].id
                        );
                    });
                }
                return false;
            }
            return true;
        });
    }
}

/**
 * Store search input in local storage
 * @param {String} searchInput The input to be stored
 * @return {void}
*/
function storeSearchInput(searchInput) {
    let searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
    if (!searchHistory) {
        searchHistory = [];
    }

    // Encode HTML in searchInput
    let encodedSearchInput = encodeURIComponent(searchInput);

    // Check if searchHistory has more than 5 items
    if (searchHistory.length >= 5) {
        // If it does, remove the last item
        searchHistory.pop();
    }

    // Check if searchInput exists in searchHistory
    if (searchHistory.includes(encodedSearchInput)) {
        // If it does, bring it to the first item
        searchHistory.splice(searchHistory.indexOf(encodedSearchInput), 1);
    }
    // Push searchInput to the beginning of searchHistory
    searchHistory.unshift(encodedSearchInput);
    localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
}

/**
 * Retrieves the search history from local storage and decodes HTML in each item of the search history
 * @return {Array<string>} Array of decoded search history items
 */
function getSearchHistory() {
    let searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
    if (!searchHistory) {
        searchHistory = [];
    }
    // Decode HTML in each item of searchHistory
    searchHistory = searchHistory.map((item) => {
        return decodeURIComponent(item);
    });
    return searchHistory;
}

/**
 * Escapes HTML special characters in a string
 * @param {String} string The string to be escaped
 * @return {String} Escaped string
 */
function escapeHtml(string) {
    return string.replace(/&/g, '&amp;')
                 .replace(/</g, '&lt;')
                 .replace(/>/g, '&gt;')
                 .replace(/"/g, '&quot;')
                 .replace(/'/g, '&#039;');
}

/**
 * Parses the search history to generate HTML tags for the previous search inputs
 * @param getSearchHistory Function that returns an array of previous search inputs
 * @return {String} HTML tags for the previous search inputs
 */
function parseSearchHistory() {
    let previousSearchInputs = getSearchHistory();
    let prevSearchHtmlTag = "";
    let searchEndpoint = $('div#search-result').data('search');
    if (previousSearchInputs && previousSearchInputs.length) {
        for (const input of previousSearchInputs) {
            let encodedInput = encodeURIComponent(input);
            prevSearchHtmlTag += `<li role="option"><a href="${
                searchEndpoint + '?q=' + encodedInput
            }">${escapeHtml(input)}</a></li>`;
        }
    }
    return prevSearchHtmlTag;
}

/**
 * Append search history to the page
 */
function appendSearchHistory() {
    let prevSearchHtmlTag = parseSearchHistory();
    $(document).find("ul.recent_search").html(prevSearchHtmlTag);
}


module.exports = function () {
    $('form[name="simpleSearch"]').submit(function (e) {
        var searchValue = $(this).find('input.search-field').val();
        if (!searchValue || searchValue.trim().length === 0) {
            e.preventDefault();
            return;
        }
        var suggestionsList = $(".suggestions .item");
        if (suggestionsList.filter(".selected").length !== 0) {
            e.preventDefault();
            suggestionsList.filter(".selected").find("a")[0].click();
        }
    });

    $("input.search-field").each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 500);
        $(this).on("keyup focus", function (e) {
            var searchValue = $(this).val();
            if (!searchValue || searchValue.trim().length === 0) {
                e.preventDefault();
                return;
            }
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });

    $("body").on("click", function (e) {
        if (
            !$(".suggestions").has(e.target).length &&
            !$(e.target).hasClass("search-field")
        ) {
            $(".suggestions").hide();
        }
    });

    $("body").on(
        "click touchend",
        ".search-mobile button.fa-close",
        function (e) {
            e.preventDefault();
            $(".suggestions").hide();
            toggleSuggestionsIcon("search");
            tearDownSuggestions();
        }
    );

    $(() => {
        appendSearchHistory();
    });
};
