'use strict';

function setConsent(result) {
    var trackingConsentData = $('.tracking-consent');

    var url = trackingConsentData.data(result);
    var tokenName = trackingConsentData.data('tokenname');
    var token = trackingConsentData.data('token');
    var data = {};
    data[tokenName] = token;
    $.ajax({
        url: url,
        type: 'post',
        data: data,
        dataType: 'json',
        success: function (response) {
            if (response.success) {
                $('.cookie').fadeOut(300);
            }
        },
        error: function (err) {
            // Expected error response is for CSRF failure, which will include a redirect to CSRF-Fail
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }
        }
    });
}

$(() => {

    if ($('.tracking-consent').data('tracking') === null && $('.tracking-consent').hasClass('api-true')) {
        $('.cookie').css('display', 'flex');
    }

    $('body').on('click', '.jsCookieYes', function(e) {
        e.preventDefault();
        setConsent('accept');
    });

    $('body').on('click', '.jsCookieNo', function(e) {
        e.preventDefault();
        setConsent('reject');
    });
});
