window.jQuery = require('jquery');
window.$ = require('jquery');
const processInclude = require('./util');
var base = require('base/main');

$(document).ready(() => {
    // baseから変更がある場合は、ここに追加する。なければbaseを読み込むことで対応
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/search'));
    processInclude(require('./cookie'));
    processInclude(require('./information_bar'));
    processInclude(require('./sendgrid'));
});

require('slick-carousel');
require('magnific-popup');
